import { Box } from "@mui/system";
import * as React from "react";
import { blueMedium, orange } from "../utils/colors";

const QuestionsComponent = ({ questions }) => {
  const handleClick = (link) => {
    let el = document.getElementById(link);
    console.log("xx", el);
    el.scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <div>
      {questions?.map((el, index) => {
        return (
          <div
            key={el.title}
            onClick={() => {
              handleClick(el.link);
            }}
            style={{
              color: blueMedium,
              fontSize: "1.2em",
              fontWeight: 300,
              lineHeight: "24px",
              marginTop: 10,
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <div style={{ width: 30, marginRight: 10 }}>
              <div
                style={{
                  borderRadius: "50%",
                  width: 30,
                  height: 30,
                  borderWidth: 1,
                  borderColor: blueMedium,
                  color: "white",
                  borderStyle: "solid",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: blueMedium,
                }}
              >
                {index + 1}
              </div>
            </div>
            <Box
              sx={{
               
                "&:hover": {
                  color: orange
                },
              }}
            >
              {el.title}
            </Box>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionsComponent;
